/* eslint-disable jsx-a11y/alt-text */
import { useMemo, useState } from "react";
import { Carousel, Modal } from "react-bootstrap";
import { MenuButton } from "./MenuButton";
import { useContent } from "../data";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { EMPTY_DOCUMENT } from "@contentful/rich-text-types";
import { useLocation } from "react-router-dom";

export function PageTemplate() {
  const location = useLocation()
  const { pages } = useContent()
  const [showModal, setShowModal] = useState(false)
  const [carouselIndex, setCarouselIndex] = useState(0)

  const page = useMemo(() => {
    return pages?.find(page => page.path === location.pathname)
  }, [location.pathname, pages])

  if (!page) {
    return null
  }


  return (
    <>
      {page.media.items[carouselIndex] && (
        <Modal 
          centered 
          show={showModal} 
          onHide={() => setShowModal(false)}
          autoFocus={true}
        >
          <Modal.Body>
            {/* <img
              className='page-carousel-img' 
              src={page.media.items[carouselIndex].url}
            /> */}
            {page.media.items[carouselIndex].contentType.startsWith('image') && (
              <img
                className='page-carousel-img' 
                src={page.media.items[carouselIndex].url}
                onClick={() => setShowModal(true)}
              />
            )}
            {page.media.items[carouselIndex].contentType.startsWith('video') && (
              <video
                autoPlay
                controls
                className='page-carousel-img' 
                src={page.media.items[carouselIndex].url}
                onClick={() => setShowModal(true)}
              />
            )}
            {/* <video autoPlay muted onstart /> */}
          </Modal.Body>
        </Modal>
      )}
      <div className='page-container'>
        <figure className='page-media'>
          <Carousel 
            className='page-carousel' 
            interval={showModal ? null : undefined}
            activeIndex={carouselIndex}
            onSelect={(index) => setCarouselIndex(index)}
          >
            {page.media.items?.map((asset) => (
              <Carousel.Item key={asset.url}>
                {asset.contentType.startsWith('image') && (
                  <img
                    className='page-carousel-img' 
                    src={asset.url}
                    onClick={() => setShowModal(true)}
                  />
                )}
                {asset.contentType.startsWith('video') && (
                  <div className='page-carousel-container' onClick={() => setShowModal(true)}>
                    <svg 
                      className='page-carousel-vid-icon'
                      width="50px" 
                      height="50px" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      xmlns="http://www.w3.org/2000/svg" 
                      color="#000000" 
                      opacity={0.5}
                      stroke-width="1.5"
                    >
                      <path d="M6.90588 4.53682C6.50592 4.2998 6 4.58808 6 5.05299V18.947C6 19.4119 6.50592 19.7002 6.90588 19.4632L18.629 12.5162C19.0211 12.2838 19.0211 11.7162 18.629 11.4838L6.90588 4.53682Z"
                        fill="#fff"
                        stroke="#fff"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <video
                      className='page-carousel-vid'
                      style={{width: '100%', height: '100%'}}
                      src={asset.url}
                    />
                  </div>
                )}
              </Carousel.Item>
            ))}
          </Carousel>
        </figure>
        <section className='page-content'>
          {page.menus.items.map((menu) => (
            <nav className='page-submenu'>
              {menu.links.items.map((buttonProps) => (
                <MenuButton {...buttonProps} size="small" />
              ))}
            </nav>
          ))}

          <article 
            className='page-content-text' 
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(page.content?.json ?? EMPTY_DOCUMENT)
            }} 
          />
        </section>
      </div>
    </>
  )
}