/* eslint-disable jsx-a11y/alt-text */
import { Link, useParams } from 'react-router-dom';
import { ArchiveGroupData, ArchiveItemData, isArchiveGroup, useContent } from '../data';
import './styles.css';
import { useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';

function ArchiveGroupCard({name, slug, thumbnail}: ArchiveGroupData) {
  return (
    <Link to={slug ?? ''} className='archive-group-item-container'>
      <div className='archive-group-item'>
        {thumbnail && (
          <img className='archive-group-img' src={thumbnail.url}/>
        )}
      </div>
      <div>{name}</div>
    </Link>
  )
}

function ArchiveItemCard(props: ArchiveItemData) {
  if (props.asset.contentType.startsWith('image')) {
    return <ArchiveItemImageCard {...props} />
  }
  return <ArchiveItemFileCard {...props} />
}

function ArchiveItemFileCard({name,asset,thumbnail}: ArchiveItemData) {
  return (
    <Link to={asset.url} download className='archive-group-item-container'>
      <div className='archive-group-item'>
        {thumbnail && (
          <img className='archive-group-img' src={thumbnail.url}/>
        )}
      </div>
      <div style={{display: 'flex', gap: '0.5em'}}>
        <svg 
          width="24px" 
          height="24px"
          style={{marginLeft: '0.5em'}} 
          stroke-width="1.5" 
          viewBox="0 0 24 24" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg" 
          color="#000000"
        >
          <path d="M9 17L15 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M12 6V13M12 13L15.5 9.5M12 13L8.5 9.5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
        <div style={{flex: 1}}>{name}</div>
      </div>
    </Link>
  )
}
function ArchiveItemImageCard({name,asset}: ArchiveItemData) {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <Modal 
        centered 
        show={showModal} 
        onHide={() => setShowModal(false)}
        autoFocus={true}
      >
        <Modal.Body>
          <img
            className='page-carousel-img' 
            src={asset.url}
          />
        </Modal.Body>
      </Modal>
      <div onClick={() => setShowModal(true)} className='archive-group-item-container'>
        <div className='archive-group-item' >
          <img className='archive-group-img' src={asset.url}/>
        </div>
        <div>{name}</div>
      </div>
    </>
  )
}

function ArchivePage() {
  const { archives } = useContent()
  const { "*": archivePath} = useParams()
  const archive = useMemo(() => {
    const path = archivePath ? archivePath : null
    return archives?.find(a => a.slug === path)
  }, [archivePath, archives])

  return (
    <div className="page-container">
      <div className="archive-container">
        {archive && (
          archive.list.items.map(item => {
            if (isArchiveGroup(item)) {
              return <ArchiveGroupCard key={item.sys.id} {...item} />
            }
            return <ArchiveItemCard key={item.sys.id} {...item} />
          })
        )}
      </div>
    </div>
  )
}

export default ArchivePage;