import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ContentProvider } from './data';
import { PageTemplate } from './components/PageTemplate';
import ContactForm, { ContactProvider } from './components/ContactForm';
import ArchivePage from './components/ArchivePage';
import HomeAnimation from './components/HomeAnimation';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: '/',
        element: <HomeAnimation />
      },
      {
        path: '/about/*',
        element: <PageTemplate />,
      },
      {
        path: '/project/*',
        element: <PageTemplate />,
      },
      {
        path: "/sites/*",
        element: <PageTemplate />
      },
      {
        path: "trajectories",
        element: <div>trajectories</div>
      },
      {
        path: "archive/*",
        element: <ArchivePage />
      },
      {
        path: "contact",
        element: <ContactForm />
      },
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ContentProvider>
      <ContactProvider>
        <RouterProvider router={router} />
      </ContactProvider>
    </ContentProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
