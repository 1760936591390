import { useMemo } from "react";
import { Link, useLocation, useNavigation } from "react-router-dom";

type ButtonSize = "small" | "large"

export interface MenuButtonProps {
  path: string;
  name: string;
  size?: ButtonSize;
  callback?: () => void
}

export function MenuButton({ path, name, size = 'large', callback }: MenuButtonProps) {
  const location = useLocation()
  const isActive = useMemo(() => {
    return location.pathname === path
  }, [location.pathname, path])
  const className = useMemo(() => {
    let className = 'menu-link'
    if (isActive) {
      className = className + ' active'
    }
    if (size === 'small') {
      className = className + ' smaller'
    }
    return className
  }, [isActive, size])
  return (
    <Link className={className} to={path} onClick={callback}>{name.toUpperCase()}</Link>
  );
}