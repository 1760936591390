import { P5CanvasInstance, ReactP5Wrapper } from "@p5-wrapper/react"
import { useCallback, useEffect, useState } from "react";

function sketch(width?: number , height?: number) {
  return (p5: P5CanvasInstance) =>  {
    let initWidth = width
    let initHeight = height
    if (!width) {
      initWidth = document.getElementById('animation-container')?.getBoundingClientRect().width
    }
    if (!height) {
      initHeight = document.getElementById('animation-container')?.getBoundingClientRect().height
    }

    let aa: any[]; // great naming conventions, I know...
    let bb: any[];
    let transition = 0;
    let t = 0; // keeps the time
    let reflex = 1; // how fast ir reacts when clicked. Range: [0, 1[
    let num = 40; // how many rows and columns
    let aperture = 10; // how much it blurs
    let s = 10; // size of the dots
    let fg1 = p5.color(20); // foreground
    let fg2 = p5.color(60);

    p5.setup = () => {
      p5.createCanvas(initWidth, initHeight)
      // p5.fullScreen();
      p5.noStroke();
      p5.frameRate(100);
      aa = [0,0,0,0,0,0,0,0];
      bb = [0,0,0,0,0,0,0,0];
      p5.reset();
    };

    p5.reset = () => {
      for (let i = 0; i < bb.length; i++) {
        let v = p5.random(1, 8);
        bb[i] = aa[i];
        aa[i] = p5.random(2) < 1 ? v : -v;
      }
      transition = 1;
    }

    p5.mouseClicked = () => {
      p5.reset()
    }
  
    p5.draw = () => {
      // t += 0.001; // enables time traveling
      t += 0.0007; // enables time traveling (slower)
      p5.background(255);
      transition *= 1 - reflex;
      for (let v = 0; v < num; v++) {
        for (let u = 0; u < num; u++) {
          let h =
              42 *
              p5.sin((aa[0] * u - aa[1] * v) / num + 4.2 * aa[4] * t + aa[6]) *
              p5.cos((aa[2] * v + aa[3] * u) / num + aa[5] * t + aa[7]);
          let h2 =
              42 *
              p5.sin((bb[0] * u - bb[1] * v) / num + 4.2 * bb[4] * t + bb[6]) *
              p5.cos((bb[2] * v + bb[3] * u) / num + bb[5] * t + bb[7]);

          h = p5.lerp(h, h2, transition);
          let u1 = (u / num) * 2 - 1;
          let v1 = (v / num) * 2 - 1;
          let u2 = p5.cos(t) * u1 + p5.sin(t) * v1;
          let v2 = p5.sin(t) * u1 - p5.cos(t) * v1; // transformation after transformation, I'm not entirely sure what's going on here anymore. #dontTouchItItWorks
          let u3 = u2 / 2 + 0.5;
          let v3 = v2 / 2 + 0.5;
          let x = u3 * p5.min(p5.width, p5.height);
          let y = v3 * p5.min(p5.width, p5.height);
          let a = x - y;
          let b = x + y;

          p5.push();
          p5.translate(0, 0);
          p5.translate(p5.width / 2, 0);
          let dist = 1.5 - b / 2 / p5.height - h / 500;
          p5.scale(p5.atan(1 / dist) * 3.4); // a little math, but mostly magic
          p5.translate(-p5.width / 2, -p5.height / 3.5);
          let blur = (1 - dist) * aperture;
          p5.fill(
            p5.lerpColor(
              fg1,
              fg2,
              p5.constrain(
                p5.map(blur, -aperture / 3, aperture / 2, 0, 1),
                0,
                1
              )
            ), // blend the colors
            p5.min((s * 255) / p5.max(p5.sq(blur / 2) * 2, 1), 128)
          ); // add transparency

          p5.translate(0, -h); // creates the waves
          p5.ellipse(
              a / 4 + p5.width / 2,
              b / 42 + p5.height / 2,
              p5.max(p5.abs(blur), s),
              p5.max(p5.abs(blur), s)
          );
          p5.pop();
        }
      }
    };
  }
}

function HomeAnimation() {
  const [containerWidth, setContainerWidth] = useState(document.getElementById('animation-container')?.getBoundingClientRect().width)
  const [containerHeight, setContainerHeight] = useState(document.getElementById('animation-container')?.getBoundingClientRect().height)
  const handleResize = useCallback(() => {
    const element = document.getElementById('animation-container')
    if (element) {
      const bound = element.getBoundingClientRect()
      setContainerWidth(bound.width)
      setContainerHeight(bound.height)
    }
  }, [])
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize])
  return (
    <div id="animation-container" style={{display:'flex',width:'100%', height:'100%'}}>
      <ReactP5Wrapper sketch={sketch(containerWidth, containerHeight)} />
    </div>
  )
}

export default HomeAnimation