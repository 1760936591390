import { Document } from "@contentful/rich-text-types"
import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react"

const query = `
  query {
    pages: pageCollection(limit: 22) {
      items {
        name
        path
        media: mediaCollection(limit: 20) {
          items {
            url
            contentType
          }
        }
        menus: menusCollection(limit: 2) {
          items {
            links: linksCollection {
              items {
                name
                path
              }
            }
          }
        }
        content {
          json
        }
      }
    }
    archives: archiveGroupCollection(limit: 20) {
      items {
        name
        slug
        thumbnail {
          url
        }
        list: itemsCollection(limit: 20) {
          items {
            ...on ArchiveItem {
              type: __typename
              name
              source
              thumbnail {
                url
              }
              asset {
                url
                contentType
              }
              sys {
                id
              }
            }
            ...on ArchiveGroup {
              type: __typename
              name
              slug
              thumbnail {
                url
              }
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`

interface ContentType {
  pages?: PageData[]
  archives?: ArchiveData[]
}

type ArchiveEntry = {
  type: 'ArchiveGroup' | 'ArchiveItem';
  sys: {
    id: string
  }
}

export type ArchiveGroupData = ArchiveEntry & {
  name: string;
  slug: string | null;
  thumbnail: {
    url: string
  } | null;
}

export type ArchiveItemData = ArchiveEntry & {
  name: string;
  source: string | null;
  thumbnail: {
    url: string
  } | null;
  asset: {
    url: string;
    contentType: string;
  };
}

type ArchiveListItem = ArchiveGroupData | ArchiveItemData

export function isArchiveItem(item: ArchiveListItem): item is ArchiveItemData {
  return item.type === 'ArchiveItem';
}

export function isArchiveGroup(item: ArchiveListItem): item is ArchiveGroupData {
  return item.type === 'ArchiveGroup';
}

export interface ArchiveData {
  name: string;
  slug: string | null;
  thumbnail: {
    url: string;
  } | null;
  list: {
    items: ArchiveListItem[]
  }
}

interface PageData {
  name: string;
  path: string;
  content: { 
    json: Document 
  } | null;
  menus: {
    items: {
      links: {
        items: {
          name: string;
          path: string;
        }[]
      }
    }[]
  };
  media: {
    items: { 
      url: string;
      contentType: string;
    }[]
  };
}

const ContentContext = createContext<ContentType>({})

export const useContent = () => {
  return useContext(ContentContext)
}

export const ContentProvider = ({children}: PropsWithChildren) => {
  const [pages, setPages] = useState<PageData[]>();
  const [archives, setArchives] = useState<ArchiveData[]>();

  useEffect(() => {
    fetch('https://graphql.contentful.com/content/v1/spaces/8t8qwvquuwks/environments/master', {
      method: 'POST',
      headers: {
        Authorization: `Bearer FSLnCZulVnhX3fWnQbSQkXYmKmM7ZavUIXXLVqLlkD8`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({query})
    })
    .then(res => res.json())
    .then(json => {
      setPages(json.data.pages.items)
      setArchives(json.data.archives.items)
    })
  }, [])

  return (
    <ContentContext.Provider value={{pages, archives}}>
      {children}
    </ContentContext.Provider>
  );
}



