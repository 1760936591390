/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useState } from 'react';
import './App.css';
import Logo from './components/Logo';
import { Link, Outlet } from 'react-router-dom';
import { MenuButton, MenuButtonProps } from './components/MenuButton';

const menuButtons: MenuButtonProps[] = [
  {
    path: '/project',
    name: 'Project'
  },
  {
    path: '/sites',
    name: 'SITES + WORKS'
  },
  {
    // TODO: UPDATE THIS PATH TO BE A URL TO THE MAP
    path: 'https://mapa.wovenmemory.net',
    name: 'TRAJECTORIES'
  },
  {
    path: '/archive',
    name: 'ARCHIVE'
  },
  {
    path: '/contact',
    name: 'CONTACT'
  },
]

function Menu() {
  const [showSidebar, setShowSidebar] = useState(false)
  const toggleSidebar = useCallback(() => {
    setShowSidebar(show => !show)
  }, [])
  return (
    <>
      <nav className='menu'>
        {menuButtons.map((button) => (
          <MenuButton key={button.path} {...button} />
        ))}
      </nav>
      <button className='menu-hamburger-mobile' onClick={toggleSidebar}>
        <svg
          role='button'
          width="24px" 
          height="24px" 
          stroke-width="1.5" 
          viewBox="0 0 24 24" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg" 
          color="#000000"
        >
          <path d="M3 5H21" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M3 12H21" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M3 19H21" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </button>
      <div
        className={showSidebar ? 'mobile-menu-overlay show' : 'mobile-menu-overlay'}
        onClick={toggleSidebar} 
      />
      <nav
        className={showSidebar ? 'mobile menu show' : 'mobile menu'}
      >
        {menuButtons.map((button) => (
          <MenuButton key={button.path} {...button} callback={toggleSidebar} />
        ))}
      </nav>
    </>
  );
}

function App() {
  return (
    <div className='app-container'>
      <header className='sidebar'>
        <Link to='' className='logo'>
          <Logo/>
        </Link>
        <Menu />
      </header>
      <main className='content'>
        <Outlet />
      </main>
    </div>
  );
}

export default App;
