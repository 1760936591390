import React from "react";

interface Stylable {
  className?: string
}

interface LogoProps extends Stylable {
  fillColor?: string
};

const Logo: React.FC<LogoProps> = ({ fillColor = "black", className }) => {
  return (
    <svg 
      id="Layer_1"
      data-name="Layer 1"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 283.46 108.06"
      height="100%"
      width="100%"
    >
      <defs>
        <clipPath id="clip-path" transform="translate(-19.25 -25.65)">
          <rect fill="none" width="320.62" height="150.88"/>
        </clipPath>
      </defs>
      <title>Logo</title>
      <g clipPath="url(#clip-path)">
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M188.53,119q.49,0,.49-.38a4.17,4.17,0,0,0-1.43-3.05,4.91,4.91,0,0,0-3.54-1.38A5.3,5.3,0,0,0,179.9,116a6.56,6.56,0,0,0-1.64,4.51,6.22,6.22,0,0,0,1.67,4.4,5.41,5.41,0,0,0,4.12,1.79,5,5,0,0,0,2.13-.48,6.36,6.36,0,0,0,1.89-1.38q.87-.9.87-1.19a.56.56,0,0,0-.12-.35.31.31,0,0,0-.24-.15l-.07,0a4.63,4.63,0,0,1-1.58,1.32A4.14,4.14,0,0,1,185,125a3.74,3.74,0,0,1-3-1.17,4.91,4.91,0,0,1-1-3.36,13.64,13.64,0,0,1,.08-1.52Zm-6.3-3.06A2.34,2.34,0,0,1,184,115a2.49,2.49,0,0,1,1.8.67,2.25,2.25,0,0,1,.7,1.69.54.54,0,0,1-.36.54,3.63,3.63,0,0,1-1.22.16l-3.78.11a4.77,4.77,0,0,1,1-2.3"
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M251.28,114.59a13.58,13.58,0,0,0-1.4.1c-.42,0-.72.07-.91.07a10.71,10.71,0,0,1-1.25-.08,11,11,0,0,0-1.27-.08c-.15,0-.22.13-.22.38s.07.35.22.37c.88.11,1.31.45,1.31,1a3.38,3.38,0,0,1-.34,1.28l-2.59,5.6-2.19-5.6a5.06,5.06,0,0,1-.37-1.48q0-.64,1.42-.82c.14,0,.22-.14.22-.37s-.07-.37-.22-.37c-.37,0-.84,0-1.42.08s-1.15.08-1.57.08-.93,0-1.49-.08a12.16,12.16,0,0,0-1.29-.08c-.14,0-.21.13-.21.37s.07.36.22.38a2.41,2.41,0,0,1,1.38.58,4.91,4.91,0,0,1,1,1.72l3.17,8.5-1.7,3.67q-.61,1.52-1.06,1.52a.93.93,0,0,1-.35-.12,1.46,1.46,0,0,0-.67-.2,1.32,1.32,0,0,0-1,.39,1.38,1.38,0,0,0-.37,1,1.23,1.23,0,0,0,.42,1,1.59,1.59,0,0,0,1.11.37q1.43,0,2.5-2.23l6.41-13.86a5.58,5.58,0,0,1,1.09-1.76,2.44,2.44,0,0,1,1.42-.54c.15,0,.22-.14.22-.37s-.08-.37-.22-.37" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M212.12,125.55a2.34,2.34,0,0,1-1.49-.56,3,3,0,0,1-.33-1.74v-5.19a3.9,3.9,0,0,0-1-2.85,3.85,3.85,0,0,0-2.89-1.06,5.94,5.94,0,0,0-4,2,3.51,3.51,0,0,0-3.52-2,5.63,5.63,0,0,0-3.65,1.82l.11-1.71c0-.15-.13-.22-.38-.22a.38.38,0,0,0-.3.16,3.8,3.8,0,0,1-1.12.7,12,12,0,0,1-2.23.77c-.14,0-.22.11-.22.33s.07.36.22.36a1.86,1.86,0,0,1,1.22.45,2.45,2.45,0,0,1,.28,1.42v5a3,3,0,0,1-.33,1.74,2.35,2.35,0,0,1-1.49.56c-.15,0-.22.14-.22.37s.07.38.22.38a13.1,13.1,0,0,0,1.33-.08q.87-.08,1.55-.08t1.54.08q.8.08,1.36.08c.14,0,.22-.13.22-.38s-.07-.35-.22-.37a1.68,1.68,0,0,1-1.23-.53,3.48,3.48,0,0,1-.29-1.77v-6.11a4.63,4.63,0,0,1,1.3-1.06,3.12,3.12,0,0,1,1.39-.3,2.3,2.3,0,0,1,1.85.71,3.41,3.41,0,0,1,.6,2.23v4.54A3.49,3.49,0,0,1,200,125a1.68,1.68,0,0,1-1.23.53c-.15,0-.22.14-.22.37s.07.38.22.38a11.56,11.56,0,0,0,1.27-.08q.8-.08,1.48-.08a14.26,14.26,0,0,1,1.44.08,11.55,11.55,0,0,0,1.27.08c.14,0,.22-.13.22-.38s-.07-.35-.22-.37A1.68,1.68,0,0,1,203,125a3.44,3.44,0,0,1-.3-1.77V116.9a3.6,3.6,0,0,1,2.71-1.11,2.25,2.25,0,0,1,1.83.7,3.47,3.47,0,0,1,.58,2.23v4.54a3.58,3.58,0,0,1-.29,1.77,1.62,1.62,0,0,1-1.21.53c-.15,0-.22.14-.22.37s.07.38.21.38a13.41,13.41,0,0,0,1.34-.08c.58-.05,1.09-.08,1.53-.08s1,0,1.54.08,1,.08,1.33.08c.14,0,.22-.13.22-.38s-.07-.35-.22-.37" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M177.42,125.55a8.19,8.19,0,0,1-1.75-.36,1.69,1.69,0,0,1-.86-.6,2.78,2.78,0,0,1-.29-1.33l-.62-12.79v-.23a1.69,1.69,0,0,1,.58-1.5,5.32,5.32,0,0,1,2.17-.59c.14,0,.22-.12.22-.37s-.08-.37-.24-.37q-.6,0-1.43.08t-1.37.08q-.46,0-1.26-.08t-1.42-.08l-6,15.09-5.83-15.09q-.5,0-1.41.08t-1.36.08q-.37,0-1.15-.08t-1.43-.08c-.06,0-.11,0-.16.12a.45.45,0,0,0-.08.25c0,.25.06.37.19.37a6,6,0,0,1,2.27.6,1.4,1.4,0,0,1,.63,1.28c0,.13,0,.27,0,.43l-1.41,12.79a2.05,2.05,0,0,1-.76,1.66,5.9,5.9,0,0,1-2.37.64c-.16,0-.24.14-.24.37s.06.38.19.38q.61,0,1.74-.08t1.84-.08q.62,0,1.76.08t1.93.08c.16,0,.24-.12.24-.37s-.06-.35-.19-.37a5.69,5.69,0,0,1-2.23-.57,1.42,1.42,0,0,1-.62-1.31,3.81,3.81,0,0,1,0-.41l1-12.52,6.17,15.77c0,.15.16.22.37.22a.36.36,0,0,0,.38-.22l6.55-16.42.57,13.16v.22a1.69,1.69,0,0,1-.58,1.49,5.32,5.32,0,0,1-2.17.58c-.15,0-.22.14-.22.37s.08.38.25.38q.91,0,2.16-.08t2-.08q.82,0,2.07.08t2.21.08c.14,0,.22-.13.22-.38s-.07-.35-.22-.37" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M219.78,114.16a6.11,6.11,0,0,0-4.56,1.81,6.22,6.22,0,0,0-1.78,4.54,6,6,0,0,0,1.77,4.44,6.84,6.84,0,0,0,9.13,0,6,6,0,0,0,1.76-4.45,6.24,6.24,0,0,0-1.77-4.55,6.09,6.09,0,0,0-4.55-1.81m2.63,10.25a3.13,3.13,0,0,1-5.27,0,7,7,0,0,1-1-3.9,7.22,7.22,0,0,1,1-4,3.06,3.06,0,0,1,5.25,0,7.26,7.26,0,0,1,1,4,7,7,0,0,1-1,3.92" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M235.64,114.16a3.35,3.35,0,0,0-1.56.41,9.26,9.26,0,0,0-1.87,1.41l.11-1.71c0-.15-.13-.22-.38-.22a.47.47,0,0,0-.33.16,3.93,3.93,0,0,1-1.14.71,12.24,12.24,0,0,1-2.22.75c-.15,0-.22.11-.22.33s.07.36.22.36a1.91,1.91,0,0,1,1.24.45,2.44,2.44,0,0,1,.28,1.42v5a3,3,0,0,1-.33,1.74,2.4,2.4,0,0,1-1.52.56c-.15,0-.22.14-.22.37s.07.38.22.38c.37,0,.85,0,1.43-.08s1.19-.08,1.64-.08,1,0,1.79.08,1.21.08,1.58.08q.22,0,.22-.38c0-.23-.07-.35-.22-.37a2.84,2.84,0,0,1-1.74-.64,2.54,2.54,0,0,1-.41-1.65v-6.11a2.58,2.58,0,0,1,1.82-1,1.5,1.5,0,0,1,.8.31,2.09,2.09,0,0,0,1.18.39,1,1,0,0,0,.81-.38,1.35,1.35,0,0,0,.33-.92,1.25,1.25,0,0,0-.45-1,1.54,1.54,0,0,0-1.07-.41" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M197,91.14a3.25,3.25,0,0,1,1.12-.29c.15,0,.22-.14.22-.37s-.07-.38-.22-.38q-.56,0-1.39.08t-1.57.08q-.63,0-1.5-.08c-.54-.05-1-.08-1.34-.08-.14,0-.21.13-.21.38s.07.35.22.37a2.57,2.57,0,0,1,1.4.58,4.7,4.7,0,0,1,1,1.71l3.65,8.88a.34.34,0,0,0,.34.22A.33.33,0,0,0,199,102l3.9-8.88a7.25,7.25,0,0,1,1-1.75,2.44,2.44,0,0,1,1.53-.55c.14,0,.22-.14.22-.37s-.08-.37-.23-.37a13.73,13.73,0,0,0-1.42.1c-.43,0-.74.07-.92.07a10.72,10.72,0,0,1-1.25-.08,11.7,11.7,0,0,0-1.29-.08c-.15,0-.22.13-.22.38s.07.35.22.37a3.25,3.25,0,0,1,1.09.29.59.59,0,0,1,.31.54,4.46,4.46,0,0,1-.43,1.47l-2.25,5.33-2.2-5.35a4.91,4.91,0,0,1-.4-1.45.59.59,0,0,1,.31-.54" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M154.06,84.37a5.48,5.48,0,0,1,1.34,2L162.22,102a.38.38,0,0,0,.39.22A.43.43,0,0,0,163,102l4.47-8.11,3.89,8.11a.43.43,0,0,0,.38.22c.21,0,.33-.07.38-.22L179,86.42a6.08,6.08,0,0,1,1.32-2.11,2.84,2.84,0,0,1,1.64-.65.29.29,0,0,0,.22-.13.47.47,0,0,0,.1-.29c0-.21-.06-.32-.19-.32a14.68,14.68,0,0,0-1.47.08,13.76,13.76,0,0,1-1.39.08q-.57,0-1.41-.08t-1.58-.08c-.16,0-.24.13-.24.37s.05.38.16.38q1.82.08,1.82,1.42a3.29,3.29,0,0,1-.27,1.23l-5.22,12.13L169,91.24l2.61-4.83a7.5,7.5,0,0,1,1.61-2.17,3.4,3.4,0,0,1,1.71-.59c.2,0,.3-.14.3-.43s-.05-.32-.16-.32q-.69,0-1.73.1c-.54,0-1,.07-1.24.07s-.89,0-1.49-.08-1.17-.08-1.6-.08c-.05,0-.11,0-.16.12a.44.44,0,0,0-.08.25c0,.25.05.38.16.38q1.82.08,1.82,1.34a3,3,0,0,1-.41,1.31l-2,3.65-2-4A3.35,3.35,0,0,1,166,84.7q0-.92,1.82-1c.09,0,.14-.12.14-.37s-.07-.37-.22-.37q-.91,0-1.85.08t-1.72.08A14.26,14.26,0,0,1,162.6,83a15.57,15.57,0,0,0-1.67-.08c-.13,0-.19.13-.19.37a.41.41,0,0,0,.09.26.24.24,0,0,0,.18.11,2.62,2.62,0,0,1,1.56.59,8.06,8.06,0,0,1,1.32,2.16l3,6.23-3.26,6.06L158,86a2.12,2.12,0,0,1-.22-.82,1.24,1.24,0,0,1,.52-1,2.65,2.65,0,0,1,1.43-.46c.09,0,.14-.12.14-.37s-.07-.37-.22-.37q-.91,0-2.09.08t-2,.08c-.54,0-1.12,0-1.74-.08s-1.3-.08-1.9-.08c-.15,0-.22.13-.22.37a.4.4,0,0,0,.1.26.27.27,0,0,0,.2.11,3.67,3.67,0,0,1,2,.72" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M216.35,94.47c.33,0,.49-.13.49-.38a4.16,4.16,0,0,0-1.43-3,4.91,4.91,0,0,0-3.54-1.38,5.31,5.31,0,0,0-4.15,1.85A6.56,6.56,0,0,0,206.08,96a6.21,6.21,0,0,0,1.67,4.4,5.41,5.41,0,0,0,4.12,1.79,5,5,0,0,0,2.13-.48,6.37,6.37,0,0,0,1.89-1.38q.87-.9.87-1.19a.56.56,0,0,0-.11-.35.32.32,0,0,0-.24-.15l-.07,0a4.62,4.62,0,0,1-1.58,1.32,4.13,4.13,0,0,1-1.94.52,3.73,3.73,0,0,1-3-1.17,4.92,4.92,0,0,1-1-3.36,13.41,13.41,0,0,1,.08-1.52Zm-6.3-3.06a2.34,2.34,0,0,1,1.81-.88,2.49,2.49,0,0,1,1.8.67,2.24,2.24,0,0,1,.7,1.69.54.54,0,0,1-.36.54,3.63,3.63,0,0,1-1.22.16l-3.78.11a4.77,4.77,0,0,1,1-2.3" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M189.78,91.47a6.64,6.64,0,0,0-9.11,0A6.22,6.22,0,0,0,178.88,96a6,6,0,0,0,1.77,4.44,6.83,6.83,0,0,0,9.13,0A6,6,0,0,0,191.54,96a6.24,6.24,0,0,0-1.77-4.55m-1.93,8.44a3.13,3.13,0,0,1-5.27,0,7,7,0,0,1-1-3.9,7.23,7.23,0,0,1,1-4,3.06,3.06,0,0,1,5.25,0,7.26,7.26,0,0,1,1,4,7,7,0,0,1-1,3.92" 
        />
        <path 
          fill={fillColor}
          transform="translate(-19.25 -25.65)"
          d="M229.49,101.64q.66,0,1.53.08t1.33.08c.14,0,.22-.13.22-.38s-.07-.35-.22-.37a2.34,2.34,0,0,1-1.49-.56,3,3,0,0,1-.33-1.74V93.57a3.9,3.9,0,0,0-1-2.85,3.85,3.85,0,0,0-2.88-1.06A5.59,5.59,0,0,0,223,91.48l.11-1.71c0-.15-.13-.22-.38-.22a.39.39,0,0,0-.3.16,3.82,3.82,0,0,1-1.13.7,12.14,12.14,0,0,1-2.24.77c-.15,0-.22.11-.22.33s.07.36.22.36a1.91,1.91,0,0,1,1.24.45,2.44,2.44,0,0,1,.28,1.42v5a3,3,0,0,1-.34,1.73,2.38,2.38,0,0,1-1.51.56c-.15,0-.22.14-.22.37s.07.38.22.38c.37,0,.82,0,1.36-.08s1.09-.08,1.52-.08,1,0,1.55.08,1,.08,1.36.08c.14,0,.22-.13.22-.38s-.07-.35-.22-.37a1.68,1.68,0,0,1-1.23-.53,3.49,3.49,0,0,1-.29-1.77V92.65a4.62,4.62,0,0,1,1.3-1.06,3.11,3.11,0,0,1,1.39-.3,2.28,2.28,0,0,1,1.85.71,3.41,3.41,0,0,1,.6,2.23v4.54a3.5,3.5,0,0,1-.29,1.77,1.68,1.68,0,0,1-1.23.53c-.15,0-.22.14-.22.37s.07.38.22.38q.54,0,1.34-.08c.59-.06,1.1-.08,1.56-.08" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M206.33,70.51a1.4,1.4,0,0,0,.07-.38,1.32,1.32,0,0,0-.07-.39c0-.15-.1-.23-.16-.23a2.82,2.82,0,0,1-1.7-.61,2.64,2.64,0,0,1-.45-1.8v-5a5.3,5.3,0,0,1,.75-1.07,1.25,1.25,0,0,1,.95-.37,1,1,0,0,1,.66.25,6.09,6.09,0,0,1,.55.54,3.91,3.91,0,0,0,.65.51,1.8,1.8,0,0,0,1,.25,1.94,1.94,0,0,0,1.51-.64,2.32,2.32,0,0,0,.58-1.62,2.07,2.07,0,0,0-.78-1.68,3.33,3.33,0,0,0-2.16-.64,3.53,3.53,0,0,0-2.06.82,6.08,6.08,0,0,0-1.75,2l.14-2.69a.32.32,0,0,0-.15-.25.86.86,0,0,0-.49-.11.88.88,0,0,0-.34.08,1.83,1.83,0,0,0-.28.14q-.91.42-2.54,1a23.22,23.22,0,0,1-2.57.84c-.06,0-.11.08-.15.23a1.27,1.27,0,0,0-.07.37,1.14,1.14,0,0,0,.07.35c0,.14.1.21.15.21a2,2,0,0,1,1.32.47,2.73,2.73,0,0,1,.27,1.49V67.1a3.18,3.18,0,0,1-.34,1.81,2.51,2.51,0,0,1-1.58.59c-.06,0-.11.08-.16.23a1.36,1.36,0,0,0-.07.39,1.44,1.44,0,0,0,.07.38c0,.16.1.24.16.24q.85,0,2.09-.09t2.2-.09q1,0,2.33.09t2.22.09c.06,0,.11-.08.16-.24" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M192,54.54c0-.06-.07-.11-.21-.15a1.28,1.28,0,0,0-.41-.07,1.14,1.14,0,0,0-.35.07.52.52,0,0,0-.27.15,20.09,20.09,0,0,1-2.7,2.28,28.67,28.67,0,0,1-3.18,2,.63.63,0,0,0-.17.3,1.23,1.23,0,0,0-.08.35,1.35,1.35,0,0,0,.08.37c.06.17.12.25.2.25h1.75a.3.3,0,0,1,.26.11.47.47,0,0,1,.08.28L187,65.79a5.54,5.54,0,0,0,1.16,4A4.66,4.66,0,0,0,191.65,71a5.66,5.66,0,0,0,2.85-.69,6.08,6.08,0,0,0,1.81-1.46.09.09,0,0,0,.05-.08v-.11a.88.88,0,0,0-.1-.35,1.77,1.77,0,0,0-.21-.35.79.79,0,0,0-.31-.2.23.23,0,0,0-.28.11,3.54,3.54,0,0,1-.95.81,2.27,2.27,0,0,1-1.17.32,1.4,1.4,0,0,1-1.12-.38,1.78,1.78,0,0,1-.32-1.17V60.14l4.21.11c.11,0,.2-.12.27-.35a2.14,2.14,0,0,0,0-1.29c-.07-.25-.15-.37-.27-.37h-4.18Z" 
        />
        <path
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M258.37,54.79a2.51,2.51,0,0,0,.76-1.84A2.57,2.57,0,0,0,254,53a2.5,2.5,0,0,0,.76,1.84,2.52,2.52,0,0,0,3.62,0" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M157.93,63.58h7.8a.61.61,0,0,0,.4-.11.38.38,0,0,0,.14-.31,5.12,5.12,0,0,0-1.7-3.9,6.53,6.53,0,0,0-4.64-1.58,7,7,0,0,0-5.19,2,6.73,6.73,0,0,0-2,5,6.13,6.13,0,0,0,2.06,4.61,7.12,7.12,0,0,0,5.09,2,6.39,6.39,0,0,0,3.7-1,8.22,8.22,0,0,0,2.09-2,.25.25,0,0,0,.07-.11.49.49,0,0,0,0-.11.55.55,0,0,0-.1-.3,1.79,1.79,0,0,0-.24-.3l-.25-.18c-.1-.07-.15-.08-.17,0a4.13,4.13,0,0,1-1.84,1.27,6.25,6.25,0,0,1-2.29.51,2.76,2.76,0,0,1-2.25-.93,5.29,5.29,0,0,1-.78-3.31v-.61a3.85,3.85,0,0,1,0-.52m.74-3.67A1.47,1.47,0,0,1,160,59a1.27,1.27,0,0,1,1.15.59,2.61,2.61,0,0,1,.38,1.44q0,.73-.28.95a1.66,1.66,0,0,1-.9.24l-2.32.06a6.81,6.81,0,0,1,.59-2.37" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M178,69a1.14,1.14,0,0,1-.85.55c-.06,0-.11.1-.15.24a1.19,1.19,0,0,0-.07.38,1.32,1.32,0,0,0,.07.4c0,.15.1.23.15.23q.85,0,1.78-.09t1.89-.09q1,0,2.13.09t2,.09c.06,0,.11-.08.15-.23a1.32,1.32,0,0,0,.07-.4,1.41,1.41,0,0,0-.07-.38c0-.16-.1-.24-.15-.24a2.37,2.37,0,0,1-1.58-.58,3.25,3.25,0,0,1-.34-1.8V62a4.34,4.34,0,0,0-1.19-3,4.21,4.21,0,0,0-3.31-1.33,5,5,0,0,0-2.59.74,4.27,4.27,0,0,0-1.79,2.12l.11-2.71c0-.08,0-.15-.13-.24a.78.78,0,0,0-.5-.13.88.88,0,0,0-.34.09,1.83,1.83,0,0,0-.28.14q-.79.39-2.47,1t-2.64.88c-.06,0-.11.07-.15.21a1.15,1.15,0,0,0-.07.35,1.26,1.26,0,0,0,.07.37c0,.15.1.23.15.23a2,2,0,0,1,1.3.44,2.84,2.84,0,0,1,.26,1.51v4.45a3.27,3.27,0,0,1-.33,1.8,2.5,2.5,0,0,1-1.57.58c-.06,0-.11.1-.16.23a1.16,1.16,0,0,0-.07.39,1.28,1.28,0,0,0,.07.41q.07.21.16.21.85,0,2-.09t2.13-.09a18.84,18.84,0,0,1,1.89.09,17.68,17.68,0,0,0,1.78.09q.09,0,.16-.21a1.21,1.21,0,0,0,0-.82q-.07-.21-.16-.21a1,1,0,0,1-.83-.6,4.32,4.32,0,0,1-.27-1.79V62.37a6.2,6.2,0,0,1,1-1.77,1.73,1.73,0,0,1,1.33-.55,1.36,1.36,0,0,1,1.31.59,4.48,4.48,0,0,1,.32,2v4.5A4,4,0,0,1,178,69" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M302.45,68.8c-.18-.2-.31-.27-.38-.21a1.37,1.37,0,0,1-.88.31,1,1,0,0,1-.65-.3c-.23-.2-.32-.63-.28-1.29l.14-4.92a5.51,5.51,0,0,0-.86-3.53q-.95-1.3-3.55-1.3a11.08,11.08,0,0,0-5.16,1.19q-2.3,1.19-2.3,2.6a2.09,2.09,0,0,0,.55,1.46,2,2,0,0,0,1.6.61,2.2,2.2,0,0,0,1.75-.68,4.29,4.29,0,0,0,.73-2.43,1,1,0,0,1,.55-.93,2.59,2.59,0,0,1,1.09-.23,1.09,1.09,0,0,1,.85.28,1.83,1.83,0,0,1,.25,1.16l-.08,2.77a23.93,23.93,0,0,0-6.16,2,3.09,3.09,0,0,0-1.84,2.71,2.49,2.49,0,0,0,1.12,2.23,4.85,4.85,0,0,0,2.73.74,5.83,5.83,0,0,0,2.47-.47,3.94,3.94,0,0,0,1.65-1.45,2.42,2.42,0,0,0,.95,1.4,3.12,3.12,0,0,0,1.88.52,7,7,0,0,0,2.32-.4,3.82,3.82,0,0,0,1.67-1c.06,0,.08-.07.08-.11a.45.45,0,0,1,0-.14.88.88,0,0,0-.27-.58m-6.77-1.51a1.89,1.89,0,0,1-.58,1.34,1.67,1.67,0,0,1-1,.35,1.36,1.36,0,0,1-1.06-.42,1.63,1.63,0,0,1-.38-1.13,2.52,2.52,0,0,1,.74-2,5.39,5.39,0,0,1,2.4-1.07Z" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M287,68.56a2.12,2.12,0,0,1-1.24-.44,2,2,0,0,1-.31-1.34V55.28q0-.88.07-2.29t.15-2.49a.32.32,0,0,0-.15-.26.87.87,0,0,0-.5-.11.88.88,0,0,0-.34.08,1.78,1.78,0,0,0-.28.14q-.82.39-2.6,1.06a23,23,0,0,1-3.08.95c-.06,0-.11.08-.16.23a1.3,1.3,0,0,0-.07.37,1.16,1.16,0,0,0,.07.35c0,.14.1.21.16.21a2.77,2.77,0,0,1,1.5.48,1.92,1.92,0,0,1,.43,1.47V58.7a5.41,5.41,0,0,0-1.16-.69,4.19,4.19,0,0,0-1.75-.33,6.64,6.64,0,0,0-5.06,1.94,7,7,0,0,0-1.84,5,6.85,6.85,0,0,0,1.67,4.69,5.76,5.76,0,0,0,4.55,1.89,4.4,4.4,0,0,0,2.23-.54,4,4,0,0,0,1.47-1.44l.09,1.72a.35.35,0,0,0,.13.24.48.48,0,0,0,.35.13,1.16,1.16,0,0,0,.4-.07l.28-.1a22.23,22.23,0,0,1,2.52-.85,16,16,0,0,1,2.49-.57c.06,0,.11-.07.16-.2a1.09,1.09,0,0,0,.07-.37,1.3,1.3,0,0,0-.07-.37c0-.15-.1-.23-.16-.23m-6.36-1.16a3.4,3.4,0,0,1-1,1.39,2.26,2.26,0,0,1-1.36.45,1.92,1.92,0,0,1-1.62-1,7.06,7.06,0,0,1-.66-3.6q0-3.48.72-4.41a2.12,2.12,0,0,1,1.71-.93,2.43,2.43,0,0,1,1.68.52,1.66,1.66,0,0,1,.58,1.29Z" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M258.71,57.56a.89.89,0,0,0-.34.08,1.86,1.86,0,0,0-.28.14q-1.07.51-2.7,1.1a25.26,25.26,0,0,1-3,.9c-.06,0-.11.08-.15.23a1.27,1.27,0,0,0-.07.37,1.15,1.15,0,0,0,.07.35c0,.14.1.21.15.21a2.82,2.82,0,0,1,1.48.48,1.89,1.89,0,0,1,.44,1.47v9.82a5.24,5.24,0,0,1-.61,2.66,5.67,5.67,0,0,1-2.11,1.92.18.18,0,0,0-.08.1.34.34,0,0,0,0,.13,1.2,1.2,0,0,0,.27.61q.27.38.38.35a15.75,15.75,0,0,0,5.07-3.12,5.66,5.66,0,0,0,1.88-4.17V62.71q0-1,.08-2.37t.14-2.4a.25.25,0,0,0-.13-.25.84.84,0,0,0-.49-.11" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M269.93,69.49a2.2,2.2,0,0,1-1.47-.57,2.68,2.68,0,0,1-.45-1.81V62.68q0-1,.09-2.37t.14-2.4a.25.25,0,0,0-.13-.25.85.85,0,0,0-.49-.11.92.92,0,0,0-.34.08l-.28.14q-.93.45-2.63,1.08a21.06,21.06,0,0,1-3.05.91c-.06,0-.11.08-.16.23a1.3,1.3,0,0,0-.07.37,1.16,1.16,0,0,0,.07.35q.07.21.16.24a3,3,0,0,1,1.48.45,1.84,1.84,0,0,1,.44,1.47v4.25a2.68,2.68,0,0,1-.44,1.81,2.39,2.39,0,0,1-1.48.57c-.06,0-.11.1-.16.25a1.29,1.29,0,0,0-.07.37,1.35,1.35,0,0,0,.07.4c0,.15.1.23.16.23q.85,0,2-.07t2.12-.07q1,0,2.3.07t2.19.07c.06,0,.11-.08.16-.23a1.39,1.39,0,0,0,.07-.4,1.49,1.49,0,0,0-.07-.38c0-.16-.1-.24-.16-.24" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M263.66,54.79a2.53,2.53,0,0,0,3.62,0A2.51,2.51,0,0,0,268,53a2.57,2.57,0,0,0-5.14,0,2.51,2.51,0,0,0,.76,1.84" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M236.39,60.25c.11,0,.2-.12.27-.35a2.14,2.14,0,0,0,0-1.29q-.1-.37-.27-.37H232.2l.11-3.7c0-.06-.07-.11-.21-.15a1.27,1.27,0,0,0-.41-.07,1.14,1.14,0,0,0-.35.07.52.52,0,0,0-.27.15,20.09,20.09,0,0,1-2.7,2.28,28.94,28.94,0,0,1-3.18,2,.62.62,0,0,0-.17.3,1.24,1.24,0,0,0-.08.35,1.36,1.36,0,0,0,.08.37c.06.17.12.25.2.25H227a.3.3,0,0,1,.25.11.47.47,0,0,1,.09.28l-.06,5.31a5.54,5.54,0,0,0,1.16,4,4.66,4.66,0,0,0,3.5,1.23,5.66,5.66,0,0,0,2.85-.69,6.06,6.06,0,0,0,1.81-1.46.09.09,0,0,0,.06-.08v-.11a.88.88,0,0,0-.1-.35,1.72,1.72,0,0,0-.21-.35.78.78,0,0,0-.31-.2.23.23,0,0,0-.28.11,3.53,3.53,0,0,1-.95.81,2.27,2.27,0,0,1-1.17.32,1.4,1.4,0,0,1-1.12-.38,1.77,1.77,0,0,1-.33-1.17V60.14Z" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M215.9,63.58h7.8a.61.61,0,0,0,.4-.11.38.38,0,0,0,.14-.31,5.12,5.12,0,0,0-1.7-3.9,6.53,6.53,0,0,0-4.64-1.58,7,7,0,0,0-5.19,2,6.73,6.73,0,0,0-2,5,6.13,6.13,0,0,0,2.06,4.61,7.12,7.12,0,0,0,5.09,2,6.39,6.39,0,0,0,3.7-1,8.22,8.22,0,0,0,2.09-2,.26.26,0,0,0,.07-.11.49.49,0,0,0,0-.11.55.55,0,0,0-.1-.3,1.79,1.79,0,0,0-.24-.3l-.25-.18c-.1-.07-.15-.08-.17,0a4.12,4.12,0,0,1-1.84,1.27,6.25,6.25,0,0,1-2.29.51,2.76,2.76,0,0,1-2.25-.93,5.29,5.29,0,0,1-.78-3.31v-.61a3.85,3.85,0,0,1,0-.52m.74-3.67A1.47,1.47,0,0,1,218,59a1.27,1.27,0,0,1,1.15.59,2.61,2.61,0,0,1,.38,1.44q0,.73-.28.95a1.66,1.66,0,0,1-.9.24l-2.32.06a6.81,6.81,0,0,1,.59-2.37" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M244.78,71.16a6.39,6.39,0,0,0,3.7-1,8.22,8.22,0,0,0,2.09-2,.25.25,0,0,0,.07-.11.49.49,0,0,0,0-.11.55.55,0,0,0-.1-.3,1.79,1.79,0,0,0-.24-.3l-.25-.18c-.1-.07-.15-.08-.17,0a4.13,4.13,0,0,1-1.84,1.27,6.25,6.25,0,0,1-2.29.51,2.76,2.76,0,0,1-2.25-.93,5.29,5.29,0,0,1-.78-3.31v-.61a3.85,3.85,0,0,1,0-.52h7.8a.61.61,0,0,0,.4-.11.38.38,0,0,0,.14-.31,5.12,5.12,0,0,0-1.7-3.9,6.53,6.53,0,0,0-4.64-1.58,7,7,0,0,0-5.19,2,6.73,6.73,0,0,0-2,5,6.14,6.14,0,0,0,2.06,4.61,7.12,7.12,0,0,0,5.09,2m-1.27-11.25a1.47,1.47,0,0,1,1.38-.9,1.27,1.27,0,0,1,1.15.59,2.61,2.61,0,0,1,.38,1.44q0,.73-.28.95a1.66,1.66,0,0,1-.9.24l-2.32.06a6.81,6.81,0,0,1,.59-2.37" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M188.93,46.67a6.4,6.4,0,0,0,3.7-1,8.22,8.22,0,0,0,2.09-2,.26.26,0,0,0,.07-.11.49.49,0,0,0,0-.11.55.55,0,0,0-.1-.3,1.78,1.78,0,0,0-.24-.3l-.25-.18c-.1-.07-.15-.08-.17,0A4.13,4.13,0,0,1,192.21,44a6.25,6.25,0,0,1-2.29.51,2.76,2.76,0,0,1-2.25-.93,5.28,5.28,0,0,1-.78-3.31v-.61a4,4,0,0,1,0-.52h7.8a.62.62,0,0,0,.4-.11.38.38,0,0,0,.14-.31,5.13,5.13,0,0,0-1.7-3.9,6.53,6.53,0,0,0-4.64-1.58,7,7,0,0,0-5.18,2,6.72,6.72,0,0,0-2,5,6.13,6.13,0,0,0,2.06,4.61,7.12,7.12,0,0,0,5.09,2m-1.27-11.25a1.47,1.47,0,0,1,1.38-.9,1.27,1.27,0,0,1,1.15.59,2.62,2.62,0,0,1,.38,1.44c0,.49-.1.81-.28.95a1.65,1.65,0,0,1-.9.24l-2.32.06a6.81,6.81,0,0,1,.59-2.37" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M265.58,40.87a3.09,3.09,0,0,0-1.84,2.71,2.49,2.49,0,0,0,1.11,2.23,4.85,4.85,0,0,0,2.73.74,5.83,5.83,0,0,0,2.47-.47,4,4,0,0,0,1.65-1.46,2.43,2.43,0,0,0,.95,1.4,3.12,3.12,0,0,0,1.88.52,7,7,0,0,0,2.32-.4,3.82,3.82,0,0,0,1.67-1c.06,0,.08-.07.08-.11a.47.47,0,0,1,0-.14.88.88,0,0,0-.27-.58c-.18-.2-.31-.27-.38-.21a1.32,1.32,0,0,1-.4.23,1.35,1.35,0,0,1-.48.08,1,1,0,0,1-.65-.3q-.34-.3-.28-1.29l.14-4.92a5.52,5.52,0,0,0-.86-3.53q-.95-1.3-3.55-1.3a11.08,11.08,0,0,0-5.16,1.19q-2.3,1.19-2.3,2.6a2.09,2.09,0,0,0,.55,1.46,2,2,0,0,0,1.6.61,2.21,2.21,0,0,0,1.75-.68,4.29,4.29,0,0,0,.73-2.43,1,1,0,0,1,.55-.93,2.6,2.6,0,0,1,1.09-.23,1.09,1.09,0,0,1,.85.28,1.83,1.83,0,0,1,.26,1.16l-.08,2.77a24,24,0,0,0-6.16,2m6,1.92a1.88,1.88,0,0,1-.58,1.34,1.67,1.67,0,0,1-1,.35,1.35,1.35,0,0,1-1.06-.43,1.62,1.62,0,0,1-.38-1.13,2.52,2.52,0,0,1,.73-2,5.4,5.4,0,0,1,2.4-1.07Z" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M231.39,46.69a7.37,7.37,0,0,0,5.58-2A6.54,6.54,0,0,0,238.87,40a6.73,6.73,0,0,0-1.95-4.79,8.54,8.54,0,0,0-11.14,0A6.81,6.81,0,0,0,223.87,40a6.48,6.48,0,0,0,1.94,4.69,7.46,7.46,0,0,0,5.58,2m-1.91-10.75a1.85,1.85,0,0,1,1.91-1.4,1.83,1.83,0,0,1,1.89,1.37,13.85,13.85,0,0,1,.45,4.08,12.86,12.86,0,0,1-.47,4,2,2,0,0,1-3.79,0,12.87,12.87,0,0,1-.47-4,13.51,13.51,0,0,1,.47-4.06" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M196.59,46.24q.85,0,2-.07t2.12-.07q1,0,2,.07t1.85.07c.06,0,.11-.08.16-.23a1.35,1.35,0,0,0,.07-.4,1.44,1.44,0,0,0-.07-.38c0-.16-.1-.24-.16-.24a1.15,1.15,0,0,1-1-.57,4.86,4.86,0,0,1-.23-1.81V37.85a7,7,0,0,1,1-1.72,1.69,1.69,0,0,1,1.37-.59,1.62,1.62,0,0,1,1.41.64,3.48,3.48,0,0,1,.45,2v4.47a5,5,0,0,1-.21,1.82,1.23,1.23,0,0,1-1,.55c-.06,0-.11.1-.16.25a1.27,1.27,0,0,0-.07.37,1.32,1.32,0,0,0,.07.4c0,.15.1.23.16.23q.85,0,2-.07t2.09-.07a16.51,16.51,0,0,1,1.67.07,14.93,14.93,0,0,0,1.55.07c.06,0,.11-.07.15-.21a1.27,1.27,0,0,0,.07-.41,1.4,1.4,0,0,0-.07-.38c0-.16-.1-.24-.15-.24a1.11,1.11,0,0,1-1-.57,5.11,5.11,0,0,1-.21-1.81V37.54A7.38,7.38,0,0,1,213.38,36a2,2,0,0,1,1.5-.48,1.31,1.31,0,0,1,1.27.64,4.45,4.45,0,0,1,.34,2v4.47a5.28,5.28,0,0,1-.2,1.82q-.2.44-1,.55c-.06,0-.11.1-.15.25a1.1,1.1,0,0,0,0,.78c0,.14.1.21.15.21q.85,0,1.82-.07t2-.07q1,0,2.13.07t2,.07c.06,0,.11-.08.15-.23a1.32,1.32,0,0,0,.07-.4,1.4,1.4,0,0,0-.07-.38c0-.16-.1-.24-.15-.24a2.29,2.29,0,0,1-1.58-.55,3.28,3.28,0,0,1-.34-1.82V37.48a4.38,4.38,0,0,0-1.16-3,4.14,4.14,0,0,0-3.28-1.31,5.51,5.51,0,0,0-2.69.68A5.44,5.44,0,0,0,212.08,36a3.67,3.67,0,0,0-1.72-2.09,5.48,5.48,0,0,0-2.83-.76,5,5,0,0,0-2.37.64,4.88,4.88,0,0,0-2,2.19l.14-2.69a.32.32,0,0,0-.15-.25.87.87,0,0,0-.49-.11.89.89,0,0,0-.34.08,1.79,1.79,0,0,0-.28.14q-.9.42-2.54,1a23.34,23.34,0,0,1-2.57.84c-.06,0-.11.07-.15.23a1.31,1.31,0,0,0-.07.37,1.16,1.16,0,0,0,.07.35c0,.14.1.22.15.24a2,2,0,0,1,1.29.45,2.62,2.62,0,0,1,.27,1.47v4.46a3.29,3.29,0,0,1-.33,1.82,2.41,2.41,0,0,1-1.57.55c-.06,0-.11.1-.16.25a1.1,1.1,0,0,0,0,.78c0,.14.1.21.16.21" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M258.43,31.06a2.47,2.47,0,0,0,1.81-.76,2.51,2.51,0,0,0,.76-1.84,2.57,2.57,0,0,0-5.14,0,2.51,2.51,0,0,0,.76,1.84,2.47,2.47,0,0,0,1.81.76" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M240.32,46.25q.85,0,2.09-.09t2.2-.09q1,0,2.33.09t2.22.09c.05,0,.11-.08.15-.24a1.41,1.41,0,0,0,.07-.38,1.33,1.33,0,0,0-.07-.4c0-.15-.1-.22-.15-.22a2.81,2.81,0,0,1-1.7-.61,2.63,2.63,0,0,1-.45-1.8v-5a5.32,5.32,0,0,1,.75-1.07,1.25,1.25,0,0,1,.95-.37,1,1,0,0,1,.66.25,6,6,0,0,1,.55.54,4,4,0,0,0,.65.51,1.8,1.8,0,0,0,1,.25,2,2,0,0,0,1.51-.64,2.32,2.32,0,0,0,.58-1.62,2.07,2.07,0,0,0-.78-1.68,3.32,3.32,0,0,0-2.16-.64,3.53,3.53,0,0,0-2.06.82,6.09,6.09,0,0,0-1.75,2l.14-2.69a.31.31,0,0,0-.16-.25.86.86,0,0,0-.49-.11.9.9,0,0,0-.34.08,1.77,1.77,0,0,0-.28.14q-.9.42-2.54,1a23.55,23.55,0,0,1-2.57.84c-.06,0-.11.07-.15.23a1.31,1.31,0,0,0-.07.37,1.16,1.16,0,0,0,.07.35c0,.14.1.21.15.21a2,2,0,0,1,1.31.47,2.71,2.71,0,0,1,.27,1.48v4.44a3.2,3.2,0,0,1-.34,1.81,2.52,2.52,0,0,1-1.58.59c-.06,0-.11.07-.16.22a1.36,1.36,0,0,0-.07.4,1.44,1.44,0,0,0,.07.38c0,.16.1.24.16.24" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M152.16,46.24c.64,0,1.25,0,1.84-.07s1.22-.07,1.89-.07,1.29,0,1.88.07,1.25.07,2,.07c.06,0,.11-.07.17-.21a1.1,1.1,0,0,0,.09-.41,2.34,2.34,0,0,0,0-.38c0-.16-.07-.24-.13-.24a4.06,4.06,0,0,1-2.22-.69,2,2,0,0,1-.49-1.68L157.82,31l6.24,15.43a1,1,0,0,0,.26.14,1.59,1.59,0,0,0,.59.08,1.79,1.79,0,0,0,.57-.08c.17-.06.26-.11.28-.14l6.19-16.05.51,12.21a2,2,0,0,1-.49,1.67,3.76,3.76,0,0,1-2.1.71c-.06,0-.11.1-.15.25a1.27,1.27,0,0,0-.07.37,1.1,1.1,0,0,0,.08.41c.06.14.11.21.17.21.92,0,1.84,0,2.76-.07s1.8-.07,2.67-.07,1.78,0,2.74.07,1.9.07,2.83.07c.06,0,.1-.08.14-.23a1.65,1.65,0,0,0,.06-.4,1.49,1.49,0,0,0-.07-.38c0-.16-.1-.24-.16-.24a3.76,3.76,0,0,1-2.22-.71,2.51,2.51,0,0,1-.58-1.67l-.59-12.41a2.08,2.08,0,0,1,.49-1.67,3.65,3.65,0,0,1,2.11-.71q.09,0,.14-.24a1.82,1.82,0,0,0,.06-.38,1.33,1.33,0,0,0-.07-.4c0-.15-.1-.23-.15-.23q-.85,0-2,.07t-2,.07q-.73,0-1.88-.07t-2.22-.07L167,39.38,161.8,26.6q-.9,0-2.21.07t-2.09.07q-.62,0-1.8-.07t-1.89-.07c-.06,0-.12.07-.18.21a1,1,0,0,0-.1.41,1.53,1.53,0,0,0,.06.37c0,.15.08.24.14.25a4.5,4.5,0,0,1,2.23.69,1.9,1.9,0,0,1,.51,1.68L155.3,42.62a2.3,2.3,0,0,1-.75,1.68,4.87,4.87,0,0,1-2.3.69c-.06,0-.12.1-.18.23a.88.88,0,0,0-.1.4A1.79,1.79,0,0,0,152,46c0,.16.09.24.14.24" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M263.05,46a1.36,1.36,0,0,0,.07-.4,1.44,1.44,0,0,0-.07-.38c0-.16-.1-.24-.16-.24a2.2,2.2,0,0,1-1.47-.56,2.68,2.68,0,0,1-.45-1.81V38.19q0-1,.08-2.37t.14-2.4a.25.25,0,0,0-.13-.25.85.85,0,0,0-.5-.11.89.89,0,0,0-.34.09,1.77,1.77,0,0,0-.28.14q-.93.45-2.63,1.08a21.12,21.12,0,0,1-3.05.91c-.06,0-.11.08-.15.23a1.27,1.27,0,0,0-.07.37,1.14,1.14,0,0,0,.07.35c0,.14.1.22.15.24a3,3,0,0,1,1.48.45,1.84,1.84,0,0,1,.44,1.47v4.25a2.68,2.68,0,0,1-.44,1.81,2.38,2.38,0,0,1-1.48.56c-.06,0-.11.1-.15.26a1.27,1.27,0,0,0-.07.37,1.32,1.32,0,0,0,.07.4c0,.15.1.23.15.23q.85,0,2-.07t2.12-.07q1,0,2.3.07t2.19.07c.06,0,.11-.08.16-.23" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M56.38,41.86H86.16a.7.7,0,1,0,0-1.4H56.38a.7.7,0,1,0,0,1.4" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M86.86,76.39a.7.7,0,0,0-.7-.7H56.38a.7.7,0,0,0,0,1.4H86.16a.7.7,0,0,0,.7-.7" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M50.44,76.39a.7.7,0,0,0-.7-.7H20a.7.7,0,0,0,0,1.4H49.74a.7.7,0,0,0,.7-.7" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M105,58.87a.7.7,0,0,0-.7-.7H74.49a.7.7,0,1,0,0,1.4h29.78a.7.7,0,0,0,.7-.7" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M74.49,94.8h29.78a.7.7,0,1,0,0-1.4H74.49a.7.7,0,1,0,0,1.4" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M38.85,59.57H68.63a.7.7,0,1,0,0-1.4H38.85a.7.7,0,1,0,0,1.4" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M69.33,94.1a.7.7,0,0,0-.7-.7H38.85a.7.7,0,1,0,0,1.4H68.63a.7.7,0,0,0,.7-.7" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M139.91,58.17H110.13a.7.7,0,0,0,0,1.4h29.79a.7.7,0,0,0,0-1.4" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M139.91,93.4H110.13a.7.7,0,0,0,0,1.4h29.79a.7.7,0,0,0,0-1.4" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M86.16,111H56.38a.7.7,0,1,0,0,1.4H86.16a.7.7,0,1,0,0-1.4" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M122.11,76.49a.7.7,0,0,0-.7-.7H91.63a.7.7,0,1,0,0,1.4h29.78a.7.7,0,0,0,.7-.7" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M121.41,111H91.63a.7.7,0,1,0,0,1.4h29.79a.7.7,0,1,0,0-1.4" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M91.62,41.86h29.78a.7.7,0,1,0,0-1.4H91.62a.7.7,0,1,0,0,1.4" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M107.19,43.27a.7.7,0,0,0-.7.7V73.76a.7.7,0,1,0,1.4,0V44a.7.7,0,0,0-.7-.7" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M106.49,79.22V109a.7.7,0,1,0,1.4,0V79.22a.7.7,0,0,0-1.4,0" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M88.23,56.84a.7.7,0,0,0,.7-.7V26.35a.7.7,0,1,0-1.4,0V56.14a.7.7,0,0,0,.7.7" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M87.53,61.59V91.38a.7.7,0,1,0,1.4,0V61.59a.7.7,0,0,0-1.4,0" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M53.07,56.84a.7.7,0,0,0,.7-.7V26.35a.7.7,0,1,0-1.4,0V56.14a.7.7,0,0,0,.7.7" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M52.37,61.59V91.38a.7.7,0,1,0,1.4,0V61.59a.7.7,0,1,0-1.4,0" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M88.23,96.82a.7.7,0,0,0-.7.7V127.3a.7.7,0,1,0,1.4,0V97.52a.7.7,0,0,0-.7-.7" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M53.07,96.82a.7.7,0,0,0-.7.7V127.3a.7.7,0,1,0,1.4,0V97.52a.7.7,0,0,0-.7-.7" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M72,73.76V44a.7.7,0,1,0-1.4,0V73.76a.7.7,0,1,0,1.4,0" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M70.57,79.22V109a.7.7,0,1,0,1.4,0V79.22a.7.7,0,1,0-1.4,0" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M36.11,74.46a.7.7,0,0,0,.7-.7V44a.7.7,0,1,0-1.4,0V73.76a.7.7,0,0,0,.7.7" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M36.11,78.52a.7.7,0,0,0-.7.7V109a.7.7,0,1,0,1.4,0V79.22a.7.7,0,0,0-.7-.7" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M125.15,91.38V61.59a.7.7,0,1,0-1.4,0V91.38a.7.7,0,1,0,1.4,0" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M124.45,96.14a.7.7,0,0,0-.7.7v29.78a.7.7,0,0,0,1.4,0V96.84a.7.7,0,0,0-.7-.7" 
        />
        <path 
          fill={fillColor} 
          transform="translate(-19.25 -25.65)"
          d="M124.45,56.84a.7.7,0,0,0,.7-.7V26.35a.7.7,0,1,0-1.4,0V56.14a.7.7,0,0,0,.7.7" 
        />
      </g>
    </svg>
  );
}

export default Logo;