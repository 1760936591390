import { PropsWithChildren, createContext, useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const ContactContext = createContext({hasContacted: false, markContacted: () => {}})
const useContact = () => {
  return useContext(ContactContext)
}
export const ContactProvider = ({children}: PropsWithChildren) => {
  const [hasContacted, setHasContacted] = useState(false)
  return (
    <ContactContext.Provider value={{hasContacted, markContacted: () => setHasContacted(true)}}>
      {children}
    </ContactContext.Provider>
  )
}

function ContactForm() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const {hasContacted, markContacted} = useContact();
  const [error, setError] = useState(false)


  return (
    <div className="contact-form-container">
      {error && (
        <div>Something went wrong. Please try again later</div>
      )}
      {hasContacted && (
        <div>Thank you for reaching out. We will contact you as soon as possible.</div>
      )}
      {!hasContacted && (
        <Form
          className="contact-form"
          onSubmit={e => {
            e.preventDefault()
            
            fetch("https://submit-form.com/FS91mz8LB", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify({
                name,
                email,
                subject: "Woven Memory Contact Form",
                message,
                _email: {
                  from: email,
                  subject: "Woven Memory Contact Form",
                  template: {
                    title: false,
                    footer: false,
                  },
                }
              }),
            })
            .then(r => {
              if (r.ok) {
                markContacted()
              } else {
                setError(true)
              }
            })
          }}
        >
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="name" placeholder="Enter your name" onChange={e => setName(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" onChange={e => setEmail(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formMessage">
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" rows={5} onChange={e => setMessage(e.target.value)}/>
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      )}
    </div>
  );
}

export default ContactForm;